<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editunit") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label for="mainWarehouseCode" class="required form-label col-sm-10"
            >{{ $t("unitcode") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.code"
              type="text"
              class="form-control"
              id="mainWarehouseCode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอกข้อมูล</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <label for="mainWarehouseName" class="required form-label col-sm-10"
            >{{ $t("unitname") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              id="mainWarehouseName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import whApi from "@/api/warehouse/";

export default {
  setup() {
    document.title = "bem-document-demo แก้ไขกลุ่มสินค้าหลัก";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    isSubmit: false,

    form: {
      code: "",
      name: "",
    },
  }),
  created() {
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.unit.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
        };
        this.loading = false;
      }
    },
    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      this.form = {
        ...this.form,
        status: this.form.status === "2" ? "0" : this.form.status,
        companyId: parseInt(localStorage.getItem("companyId")),
      };
      if (this.isValid) {
        this.loadingUpdate = true;
        updateResponse = await whApi.unit.update(
          this.$route.query.id,
          this.form
        );
        if (updateResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/unit",
              query: {},
            });
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${updateResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.form.name
        ? (this.isValid = false)
        : !this.form.code
        ? (this.isValid = false)


        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/unit",
        query: {},
      });
    },
  },
};
</script>
